import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Paper,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (email === 'admin' && password === 'password') {
      localStorage.setItem('authToken', 'admin-token'); // Save token
      navigate('/userlist'); // Redirect to UserList
    } else {
      setError('Invalid credentials. Please try again.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 8, borderRadius: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ backgroundColor: '#1d5400', mb: 2 }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5" gutterBottom>
            Sign In
          </Typography>
        </Box>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 2,
          }}
        >
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#4aa741',
                },
                '&:hover fieldset': {
                  borderColor: '#1d5400',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1d5400',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#1d5400',
              },
            }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#4aa741',
                },
                '&:hover fieldset': {
                  borderColor: '#1d5400',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1d5400',
                },
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#1d5400',
              },
            }}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            variant="contained"
            sx={{ backgroundColor: '#1d5400', color: 'white' }}
            fullWidth
            onClick={handleLogin}
          >
            Log In
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;
