import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  TextField,
  Typography,
  Box,
  Button,
} from '@mui/material';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
	try {
      const response = await axios.get(`http://167.99.12.63:4000/users?page=${page}&limit=10`);
      setUsers(response.data.users);
      setTotalPages(Math.ceil(response.data.total / 10));
   } catch (error){
	console.error('Error fetching users:', error.message);
   }
   };
    fetchUsers();
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(search.toLowerCase()) ||
    user.phoneNumber.includes(search)
  );

  return (
    <Box sx={{ padding: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#1d5400' }}>
          User List
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: '#4aa741', color: 'white' }} onClick={handleLogout}>
          Logout
        </Button>
      </Box>
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#4aa741',
            },
            '&:hover fieldset': {
              borderColor: '#1d5400',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1d5400',
            },
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#1d5400',
          },
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <CSVLink
          data={users}
          filename={'user_list.csv'}
          className="btn btn-primary"
        >
          <Button variant="contained" sx={{ backgroundColor: '#4aa741', color: 'white' }}>
            Export to CSV
          </Button>
        </CSVLink>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: '#1d5400' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Phone Number</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <TableRow
                  key={user._id}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                >
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.phoneNumber}</TableCell>
                  <TableCell>{user.address}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No users found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          sx={{
            '& .MuiPaginationItem-root': {
              color: '#4aa741',
            },
            '& .Mui-selected': {
              backgroundColor: '#1d5400',
              color: 'white',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default UserList;
